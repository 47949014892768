/* You can add global styles to this file, and also import other style files */
/*@import '../node_modules/@angular/material/prebuilt-themes/pink-bluegrey.css' */
@import "~angular-notifier/styles";
@import '~bootstrap/dist/css/bootstrap.min.css';
.my-heading {
	font-family: !important;
}


  .weblink_doc {
  	margin-left: 120px;
    text-align: center !important;
    width: 66% !important;
        text-align: center !important;
    font-size: 16px !important;
    padding-bottom: 50px;
    color: #848789;
    height: 44px;
    line-height: 22px;
  }


  .mat-ink-bar {
    background-color: var(--primary-color,$orange) !important;
    display: none !important;
    visibility: hidden !important;
    transition: none !important;
    height: 0px !important;
    width: 0px !important;
  }


.input{
  font-size: 20px;
}
